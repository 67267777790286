<script>
import { ref, toRefs, onUnmounted, watch, computed } from 'vue'
export default {
  props: {
    digit: {
      type: Number,
      default: 60
    },
    suffix: {
      type: String,
      default: 's'
    },
    clickControlled: {
      type: Boolean,
      default: false
    },
    style: {
      type: String
    }
  },
  setup(props, { emit }) {
    let digit = ref(props.digit)
    let clickable = ref(true)
    let sendArgs = ref(null)
    let timer = null
    let digitPad = computed(() => ('0' + digit.value.toString()).slice(-2))

    let setup = () => {
      if(!props.clickControlled) {
        emit('handleSend')
        return false
      }
      countDown()
    }

    let countDown = () => {
      if(digit.value) {
        digit.value -=1
        clickable.value = false
        timer = setTimeout(countDown, 1e3)
      }
      else {
        clearTimer()
        resetData()
      }
    }

    let resetData = () => {
      clickable.value = true
      digit.value = props.digit 
    }
    
    let clearTimer = () => {
      clearTimeout(timer)
      timer = null
    }

    watch(clickable, (a, b) => {
      if(!a) emit('handleSend', sendArgs)
    })

    watch(() => sendArgs.value, (a, b) => {
      if(a && !clickable.value){
        resetData()
        clearTimer()
      }
    })


    onUnmounted(clearTimer)

    return {
      setup,
      clickable,
      digitPad
    }
  }
}
</script>
<template>
  <div :style="style">
    <div @click="setup" v-show="clickable">
      <slot/>
    </div>
    <span class="digit" v-show="!clickable">
      {{digitPad}}{{suffix}}
    </span>
  </div>
</template>
<style>
    .digit { color:#999 }
</style>
