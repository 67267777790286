<script>
import { onMounted, onUnmounted, watch, ref } from 'vue'
import { http } from '@/http'
import { forgetPassword, sendCode } from '@/http/api'
import countDown from '@/components/countdown'
import md5 from 'md5'
import router from '@/routes'
import { userAuth, needLoading } from '@/reactive'
import { Toast } from 'vant'

export default {
  setup() {
    let tel = ref('')
    let password = ref('')
    let code = ref('')
    let clickable = ref(false)
    let execCountDown = ref(true)

    const onSubmit = (fields) => {
      if(needLoading.value) return false
      needLoading.value = true
      forgetPassword({
        tel: fields.tel,
        code: fields.code,
        password: md5(fields.password)
      }).then(data => {
        if(data.result) {
          Toast.success('密码重置成功, 请重新登录')
          router.push('/signin')
        }
      })
    }

    const checkLoginName = () => {
      return http.post('/?cmd=com.xqxc.api.pigear.biz.account.BrokerLoginService.checkLoginName', {
        tel: tel.value
      })
    }


    const handleSendCode = (a) => {
      if(!clickable.value) {
        Toast('请输入正确的手机号')
        return false
      }
      checkLoginName().then((res) => {
        if(res.result) {
          sendCode({
            templateCode: 'SMS_BROKER_FORGET_PASSWORD',
            smsPhoneNumber: tel.value
          }).then(data => {

          })
        }
        else {
          a.value = Date.now()
        }
      })
    }

    watch(() => tel.value, (a, b) => {
      clickable.value = /^1[345789][0-9]{9}$/.test(a)
    })

    return {
      onSubmit,
      tel,
      code,
      password,
      clickable,
      handleSendCode,
      execCountDown
    }
  },
  components: {
    countDown,
  }
}
</script>
<template>
  <div class="page">
    <div class="page-title">
      <h3>找回密码</h3>
    </div>
    <van-form @submit="onSubmit"  validate-trigger="onSubmit">
      <div class="fields-ct">
        <van-field
          v-model="tel"
          name="tel"
          label="手机号"
          type="number"
          label-width="1rem"
          maxlength="11"
          placeholder="请输入手机号码"
          style="border-bottom:1px solid #dbdbdb"
          :rules="[{
            required: true,
            message: '请输入手机号码' 
          }, {
            pattern: /^1[345789][0-9]{9}$/, 
            message: '请输入正确手机号格式'
          }]"
        />
        <van-field
          v-model="code"
          name="code"
          label="验证码"
          label-width="1rem"
          type="number"
          maxlength="4"
          placeholder="请输入验证码"
          style="border-bottom:1px solid #dbdbdb"
          :rules="[{
            required: true,
            message: '请输入验证码' 
          }]"
        >
          <template #extra>
            <countDown 
              :clickControlled="clickable" 
              @handleSend="handleSendCode"
            >
              <span class="vcode">发送验证码</span>
            </countDown>
          </template>
        </van-field>
        <van-field
          v-model="password"
          type="password"
          label-width="1rem"
          label="新密码"
          name="password"
          placeholder="请输入新的登录密码"
          style="border-bottom:0"
          :rules="[{ 
            required: true, 
            message: '请输入新的登录密码' 
          },{
            pattern: /^.{6,16}$/, 
            message: '请输入6-16位任意组合密码'
          }
          ]"
        />
      </div>
      <div style="padding: 0 2rem;margin: 30px 0 0;">
        <van-button block type="primary" native-type="submit">确认修改并登录</van-button>
      </div>
      
    </van-form>
    
  </div>
</template>
<style scoped>
  .fields-ct { padding: 0 .3rem; background-color:#fff }
  .page { overflow:hidden }
  .concat { line-height: 1.5; font-size: .24rem; text-align:center }
  .concat p {  padding-top:80%}
  h3 { font-size:.46rem; }
  .van-cell::after { border:none }
  .vcode { color:#00abeb; font-size:.26rem }
</style>